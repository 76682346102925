import React from "react";
import LearnCom from "./LearnCom";
import LearnComPage2 from "./LearnComPage2"

function LearnConnect() {
  return (
    <div>
      <div>
        <LearnCom />
        <LearnComPage2/>
      </div>
    </div>
  );
}

export default LearnConnect;
