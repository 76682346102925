import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
// import About from "./pages/About";
import Service from "./pages/Service";
import Package from "./pages/Package";
import Contact from "./pages/Contact";
import OurTeam from "./pages/OurTeam";
import Learn from "./pages/Learn";
import Review from "./pages/Review.jsx";
import PrivacyPolicy from "../src/pages/PrivacyPolicy.jsx";
import Blog from "../src/pages/Blog.jsx";
import ArticleTwo from "../src/components/blog/ArticleTwo.jsx";
import ArticleThree from "../src/components/blog/ArticleThree.jsx";
import ArticleFour from "../src/components/blog/ArticleFour.jsx";
import ArticleFive from "../src/components/blog/ArticleFive.jsx";
import ArticleSix from "../src/components/blog/ArticleSix.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "ourteam",
        element: <OurTeam />,
      },
      {
        path: "learn",
        element: <Learn />,
      },
      {
        path: "service",
        element: <Service />,
      },
      {
        path: "review",
        element: <Review />,
      },
      {
        path: "privacypolicy",
        element: <PrivacyPolicy />,
      },
      {
        path: "blog",
        element: <Blog />,
      },
      {
        path: "blogtwo",
        element: <ArticleTwo />,
      },
      {
        path: "blogthree",
        element: <ArticleThree />,
      },
      {
        path: "blogfour",
        element: <ArticleFour />,
      },
      {
        path: "blogfive",
        element: <ArticleFive />,
      },
      {
        path: "blogsix",
        element: <ArticleSix />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
