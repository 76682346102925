import React from "react";
import Reviewimg1 from "../images/review/review001.92a1affb01f291e977fd-01.jpg";
import Reviewimg2 from "../images/review/review001.92a1affb01f291e977fd-02.jpg";
import Reviewimg3 from "../images/review/review001.92a1affb01f291e977fd-04.jpg";
import Reviewimg4 from "../images/review/review001.92a1affb01f291e977fd-05.jpg";
import Reviewimg5 from "../images/review/review001.92a1affb01f291e977fd-06.jpg";
import Reviewimg6 from "../images/review/review001.92a1affb01f291e977fd-07.jpg";
import Reviewimg7 from "../images/review/review001.92a1affb01f291e977fd-08.jpg";
import Reviewimg8 from "../images/review/review001.92a1affb01f291e977fd-09.jpg";
import Reviewimg9 from "../images/review/review001.92a1affb01f291e977fd-10.jpg";
import Reviewimg10 from "../images/review/review001.92a1affb01f291e977fd-11.jpg";
import Reviewimg11 from "../images/review/review001.92a1affb01f291e977fd-12.jpg";
import Reviewimg12 from "../images/review/review001.92a1affb01f291e977fd-12.jpg";

export default function Step(props) {
  return (
    <section className="mt-20 w-full md:mt-20">
      <div className="container px-8 sm:px-10">
        <div className="grid gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
          {/* First Section */}
          <div
            className="flex flex-col items-center border-[3px] border-[#020617] bg-gray-50 px-6 py-10"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-offset="0"
            data-aos-once="true"
          >
            <h1 className="header-text py-5 text-center text-xl font-bold">
              {props.title}
            </h1>
            <div className="mb-4">
              <img src={props.img1} alt="" />
            </div>
            <div className=" text-justify text-gray-500">{props.content}</div>
          </div>

          {/* Secound Section */}
          <div
            className="flex flex-col items-center border-[3px] border-[#020617] bg-gray-50 px-6 py-10"
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-duration="1000"
            data-aos-offset="0"
            data-aos-once="true"
          >
            <div className="mb-2 flex items-center justify-center"></div>
            <h1 className="header-text py-5 text-center text-xl font-bold">
              {props.titleTwo}
            </h1>
            <div className="mb-4">
              <img src={props.img2} alt="" />
            </div>
            <div className="text-justify text-gray-500">{props.contentTwo}</div>
          </div>

          {/* Third Section */}
          <div
            className="flex flex-col items-center border-[3px] border-[#020617] bg-gray-50 px-6 py-10 md:relative md:left-[55%] lg:left-0 lg:flex"
            data-aos="fade-up"
            data-aos-delay="900"
            data-aos-duration="1000"
            data-aos-offset="0"
            data-aos-once="true"
          >
            <div className="mb-2 flex items-center justify-center"></div>
            <h1 className="header-text py-5 text-center text-xl font-bold">
              {props.titleThree}
            </h1>
            <div className="mb-4">
              <img src={props.img3} alt="" />
            </div>
            <div className="text-justify text-gray-500">
              {props.contentThree}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
