import React from "react";
import HomeServicesSection from "../components/HomeServicesSection";

function ServicesInHome() {
  return (
    <div>
      <div className=" my-10 md:mx-80">
        <div>
          <h1 className="sm:mx-15 mx-5 text-center text-3xl font-bold">
            Balancing a busy career with a professional doctorate?
          </h1>
        </div>
        <div className=" sm:mx-15 mx-5 text-center text-justify text-xl text-[#020617] sm:text-2xl">
          <br /> Prowrite offers comprehensive academic and clinical research
          services, custom-made for clinicians and medical students. With a
          cadre of seasoned research scientists and adept professional writers
          at your service, we ensure you navigate each phase of your research
          with confidence — from the initial development of your research
          proposal to the detailed editing and formatting of your manuscript,
          and ultimately to its successful publication.
        </div>
      </div>
      <div className=" mb-10 text-center ">
        <a href="https://www.facebook.com/prowrite.us">
          <button
            className="transition ease-in-out delay-150  rounded-full bg-slate-950 px-9 py-4 text-[20px] text-white hover:-translate-y-1 hover:scale-110 hover:bg-orange-500 duration-300"
            type="button"
          >
            Talk To Us Now
          </button>
        </a>
      </div>
      <div className=" flex justify-center">
        <HomeServicesSection />
      </div>
    </div>
  );
}

export default ServicesInHome;
