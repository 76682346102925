import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from "./components/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";

function App() {
  React.useEffect(() => {
    AOS.init();
  }, []);
  function handleNewEvent(event) {
    console.log("LiveChatWidget.onNewEvent", event);
  }
  
  return (
<>
      <Navbar />
    
        <Outlet />
      
      <Footer />
      <LiveChatWidget
      license="17937795"
      visibility="mminimized"
      onNewEvent={handleNewEvent}
    />
    </>
  );
}

export default App;


// <!-- Start of LiveChat (www.livechat.com) code -->
// <script>
//     window.__lc = window.__lc || {};
//     window.__lc.license = 17937795;
//     window.__lc.integration_name = "manual_channels";
//     ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
// </script>
// <noscript><a href="https://www.livechat.com/chat-with/17937795/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a></noscript>
// <!-- End of LiveChat code -->
