import React from "react";
import { FaHotel } from "react-icons/fa";
import { AiFillCar } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { MdTravelExplore } from "react-icons/md";
import { FaHiking } from "react-icons/fa";
import { GiMeal } from "react-icons/gi";
import { MdSupportAgent } from "react-icons/md";
import { RiMapPinTimeFill } from "react-icons/ri";
import { BsFillBookmarkCheckFill } from "react-icons/bs";
import { SiGoogleanalytics } from "react-icons/si";
import { FaPencil } from "react-icons/fa6";
import { HiAcademicCap } from "react-icons/hi2";

import { LuFileEdit } from "react-icons/lu";
import { MdAssistantPhoto } from "react-icons/md";
import { HiPresentationChartLine } from "react-icons/hi";
import { BiSupport } from "react-icons/bi";



import ServiceBox from "./ServiceBox";

export default function ServiceSection() {
  return (
    <section className="mt-20 w-full md:mt-32">
      <div className="container px-8 sm:px-10">
        <div className="flex flex-col items-center justify-center">
          <div
            className="mb-10 text-center"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
            data-aos-offset="0"
            data-aos-once="true"
          >
            <h1 className="mb-1 text-4xl font-extrabold text-amber-500 md:text-5xl">
              Services
            </h1>
            <h2 className="text-lg md:text-xl">Our Services</h2>
          </div>

          <div className="border-test w-full">
            <div className="grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
              <ServiceBox
                logo={<BsFillBookmarkCheckFill className="text-6xl" />}
                title="Literature Review Services"
                desc="We provide comprehensive literature reviews synthesizing relevant academic articles, books, and other sources. This service helps clients understand the current state of research on a specific topic and identifies gaps in the literature."
              />

              <ServiceBox
                logo={<SiGoogleanalytics r className="text-6xl" />}
                title="Statistical Analysis and Data Interpretation"
                desc=" Our team offers expert statistical analysis of collected data using advanced software tools such as SPSS, R, or Python. We help interpret these results in a clear and academically rigorous manner, suitable for publication in scholarly journals."
              />

              <ServiceBox
                logo={< FaPencil className="text-6xl" />}
                title="Grant Proposal Writing"
                desc=" We assist researchers in crafting compelling grant proposals to secure funding from government agencies, non-profits, and private foundations. Our services include background research, proposal writing, budget justification, and submission support."
              />

              <ServiceBox
                logo={<HiAcademicCap className="text-6xl" />}
                title="Academic Paper Writing"
                desc="Our editing team polishes academic manuscripts, improving clarity, grammar, punctuation, and overall flow, while ensuring the text adheres to the specified style guide (APA, MLA, Chicago, etc.)."
              />

              <ServiceBox
                logo={<LuFileEdit className="text-6xl" />}
                title="Editing and Proofreading Services"
                desc="Our editing team polishes academic manuscripts, improving clarity, grammar, punctuation, and overall flow, while ensuring the text adheres to the specified style guide (APA, MLA, Chicago, etc.)."
              />

              <ServiceBox
                logo={<MdAssistantPhoto className="text-6xl" />}
                title="Research Design and Methodology Consultation"
                desc="We offer consultation on designing research studies, including defining the research question, selecting appropriate methodologies, and ensuring ethical considerations are met. This service is ideal for academics and students preparing their theses or dissertations."
              />

              <ServiceBox
                logo={<HiPresentationChartLine className="text-6xl" />}
                title="Conference Paper and Presentation Preparation"
                desc="We prepare papers for presentation at academic conferences, including writing abstracts, developing presentation slides, and coaching for oral presentations."
              />

              <ServiceBox
                logo={<BiSupport className="text-6xl" />}
                title="Thesis and Dissertation Writing Support"
                desc="Our experts provide guidance and writing support throughout the process of preparing theses and dissertations, including proposal writing, chapter drafting, and the final compilation and editing stages."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
