import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../images/Blog/blogcover4.jpg";
import img2 from "../../images/Blog/blogcover5.jpg";
import img3 from "../../images/Blog/blogcover6.jpg";

function LearnComPage2() {
  return (
    <div>
      {/* Image 1 */}
      <div className=" mt-10 justify-center sm:flex sm:justify-center">
        <div className=" mx-10 mb-10 flex min-h-[500px] flex-col justify-between ">
          <div className="">
            <img className=" w-96 " src={img1} alt="" />
          </div>
          <div className=" w-96">
            <h1 className=" my-2 text-xl font-bold">
              Ethical Considerations in Medical Research: What Every Doctor
              Should Know.
            </h1>
            <p>
              A comprehensive overview of ethical issues that medical
              researchers face and how to address them to ensure the integrity
              and societal value of your research.
            </p>
          </div>
          <Link
            to={"/blogfour"}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="rounded-md bg-orange-600 px-5 py-2 text-white hover:scale-125 hover:bg-orange-400">
              Continue
            </button>
          </Link>
        </div>

        {/* Image 2 */}
        <div className=" mx-10 mb-10 flex min-h-[500px] flex-col justify-between ">
          <div className="">
            <img className=" w-96 " src={img2} alt="" />
          </div>
          <div className=" w-96">
            <h1 className=" my-2 text-xl font-bold">
              From Data to Publication: Writing a Medical Research Paper.
            </h1>
            <p>
              Detailed guidance on structuring, writing, and editing a research
              paper, including tips on how to navigate the peer review process
              successfully.
            </p>
          </div>
          <Link
            to={"/blogfive"}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="rounded-md bg-orange-600 px-5 py-2 text-white hover:scale-125 hover:bg-orange-400">
              Continue
            </button>
          </Link>
        </div>

        {/* Image 3 */}
        <div className=" mx-10 mb-10 flex min-h-[500px] flex-col justify-between ">
          <div className=" ">
            <img className=" w-96  " src={img3} alt="" />
          </div>
          <div className=" w-96">
            <h1 className=" my-2 text-xl font-bold">
              The Impact of Open Access: Expanding the Reach of Your Medical
              Research.
            </h1>
            <p>
              Discuss the benefits and logistics of publishing medical research
              in open access formats to enhance visibility, collaboration, and
              impact in the medical community.
            </p>
          </div>
          <Link
            to={"/blogsix"}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="rounded-md bg-orange-600 px-5 py-2 text-white hover:scale-125 hover:bg-orange-400">
              Continue
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LearnComPage2;
