import Carousel from "./Carousel";
import img1 from "../../images/Relevents/one.jpg";
import img2 from "../../images/Relevents/two.jpg";
import img3 from "../../images/Relevents/Three.jpg";
import img4 from "../../images/Relevents/four.jpg";
import img5 from "../../images/Relevents/five.jpg";
import img6 from "../../images/Relevents/six.jpg";

export default function ImageCarousel() {
  const slides = [img1, img2, img3, img4, img5, img6];

  return (
    <div className="relative">
      <div className="max-w-full">
        <Carousel slides={slides} />
      </div>
    </div>
  );
}
