import React from "react";
import Reviewimg1 from "../images/review/review001.92a1affb01f291e977fd-01.jpg";
import Reviewimg2 from "../images/review/review001.92a1affb01f291e977fd-02.jpg";
import Reviewimg3 from "../images/review/review001.92a1affb01f291e977fd-03.jpg";
import Reviewimg4 from "../images/review/review001.92a1affb01f291e977fd-04.jpg";
import Reviewimg5 from "../images/review/review001.92a1affb01f291e977fd-05.jpg";
import Reviewimg6 from "../images/review/review001.92a1affb01f291e977fd-06.jpg";
import Reviewimg7 from "../images/review/review001.92a1affb01f291e977fd-07.jpg";
import Reviewimg8 from "../images/review/review001.92a1affb01f291e977fd-08.jpg";
import Reviewimg9 from "../images/review/review001.92a1affb01f291e977fd-09.jpg";
import Reviewimg10 from "../images/review/review001.92a1affb01f291e977fd-10.jpg";
import Reviewimg11 from "../images/review/review001.92a1affb01f291e977fd-11.jpg";
import Reviewimg12 from "../images/review/review001.92a1affb01f291e977fd-12.jpg";

import StepSection from "../components/StepSection";
// import BookingForm from "../components/BookingForm";
import HeaderNavigation from "../components/HeaderNavigation";
// import PackageSection from "../components/PackageSection";

export default function Review() {
  return (
    <div
      className="w-full"
      data-aos="fade-zoom-in"
      data-aos-easing="ease-in-back"
      data-aos-delay="50"
      data-aos-offset="0"
    >
      <HeaderNavigation
        title="Review"
        subtitle="Review"
        image={"hero-package.jpg"}
      />
      <div className=" -mt-28 mb-10">
        {/* <PackageSection /> */}
        <StepSection
          img1={Reviewimg1}
          title="Dr. Emily Stanton"
          content="Prowrite transformed my research into a published paper with remarkable efficiency. Their attention to detail and understanding of scientific writing significantly enhanced the readability and impact of my work."
          img2={Reviewimg2}
          titleTwo="Dr. Michael Richards"
          contentTwo="The team at Prowrite provided exceptional statistical analysis support for my clinical research. Their expertise helped clarify complex data, making our findings much stronger."
          img3={Reviewimg3}
          titleThree="Nurse Practitioner Anna Choi"
          contentThree="Prowrite's literature review service is impeccable. They helped me identify all relevant studies for my thesis, saving me time and enhancing the quality of my research."
        />

        <StepSection
          img1={Reviewimg4}
          title="Psychiatrist Dr. Jonathan Peel"
          content="I was impressed with how Prowrite handled my manuscript. Their editing service not only polished my work but also ensured compliance with journal guidelines."
          img2={Reviewimg5}
          titleTwo="Dr. Linda Hargrove"
          contentTwo="The grant proposal Prowrite crafted for me was successful. Their ability to articulate the significance and methodology of my project was pivotal in securing funding."
          img3={Reviewimg6}
          titleThree="Dr. Aaron Lee"
          contentThree="As a busy physician, finding time for research is hard. Prowrite's comprehensive writing services made it possible for me to publish without compromising my clinical duties."
        />

        <StepSection
          img1={Reviewimg7}
          title="Nurse Sarah Milton"
          content="Thanks to Prowrite, I was able to co-author a research paper as a nurse. Their team is supportive and highly proficient in medical subjects."
          img2={Reviewimg8}
          titleTwo="Dr. Robert Vance"
          contentTwo="The methodological framework Prowrite developed for my study was critically acclaimed. I appreciate their scientific rigor and clear communication."
          img3={Reviewimg9}
          titleThree="Psychiatrist Dr. Chloe Winters"
          contentThree="Prowrite’s editing services refined my journal article to perfection. Their feedback was insightful, enhancing the clarity and impact of my research findings."
        />

        <StepSection
          img1={Reviewimg10}
          title="Nurse Specialist Kyle Bradford"
          content="I recommend Prowrite for any nurse looking to get involved in research. They guided me through the publication process, making it less daunting and more rewarding."
          img2={Reviewimg11}
          titleTwo="Dr. Susan Fielding"
          contentTwo="Prowrite's support in data interpretation was invaluable. Their team helped translate complex statistical results into understandable conclusions that strengthened my paper."
          img3={Reviewimg12}
          titleThree="Dr. Henry Tate"
          contentThree="Working with Prowrite was an enlightening experience. They ensured my research adhered to all ethical guidelines, which was crucial for my study’s success."
        />

        {/* <BookingForm /> */}
      </div>
    </div>
  );
}
