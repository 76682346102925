import React from "react";
// import BlogComponent from "../components/blog/BlogComponent";
import ArticleOne from "../components/blog/ArticleOne";
import ArticleTwo from "../components/blog/ArticleTwo";

function Blog() {
  return (
    <div>
      <ArticleOne />
    </div>
  );
}

export default Blog;
