import React from "react";
import LearnConnect from "../components/LearnPage/LearnConnect";

function Learn() {
  return (
    <div>
      <div>
        <LearnConnect />
      </div>
    </div>
  );
}

export default Learn;
