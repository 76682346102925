import React from "react";

function Parralax() {
  return (
    <div className="flex h-96 items-center justify-center bg-parralax1 bg-cover bg-fixed ">
      <h1 className=" text-[40px] font-bold text-white drop-shadow-[0_5px_5px_rgba(0,0,0,0.50)] sm:top-12 sm:text-[100px]">
        PROWRITE
      </h1>
    </div>
  );
}

export default Parralax;
