import React from "react";
import { Link } from "react-router-dom";
import img1 from "../../images/Blog/blogcover1.jpg";
import img2 from "../../images/Blog/blogcover2.jpg";
import img3 from "../../images/Blog/blogcover3.jpg";

function LearnCom() {
  return (
    <div>
      {/* Image 1 */}
      <div className=" mt-10 justify-center sm:flex sm:justify-center ">
        <div className=" mx-10 mb-10 flex min-h-[500px] flex-col justify-between ">
          <div className="">
            <img className=" w-96 " src={img1} alt="" />
          </div>
          <div className=" w-96 ">
            <h1 className=" my-2 text-xl font-bold">
              Crafting the Perfect Research Question: A Step-by-Step Guide for
              Medical Professionals.
            </h1>
            <p>
              Explore the foundational steps of defining and refining a research
              question that aligns with current medical challenges and
              evidence-based practices.
            </p>
          </div>
          <Link
            to={"/blog"}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="rounded-md bg-orange-600 px-5 py-2 text-white hover:scale-125 hover:bg-orange-400">
              Continue
            </button>
          </Link>
        </div>

        {/* Image 2 */}
        <div className=" mx-10 mb-10 flex min-h-[500px] flex-col justify-between ">
          <div className="">
            <img className=" w-96 " src={img2} alt="" />
          </div>
          <div className=" w-96">
            <h1 className=" my-2 text-xl font-bold">
            Statistical Analysis and Data Interpretation
            </h1>
            <p>
              Learn how to efficiently search and analyze medical literature to
              stay updated with the latest advancements and integrate them into
              your clinical research.
            </p>
          </div>
          <Link
            to={"/blogtwo"}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="rounded-md bg-orange-600 px-5 py-2 text-white hover:scale-125 hover:bg-orange-400">
              Continue
            </button>
          </Link>
        </div>

        {/* Image 3 */}
        <div className=" mx-10 mb-10 flex min-h-[500px] flex-col justify-between ">
          <div className=" ">
            <img className=" w-96  " src={img3} alt="" />
          </div>
          <div className=" w-96">
            <h1 className=" my-2 text-xl font-bold">
              Statistical Savvy: Basic and Advanced Techniques for Medical
              Researchers.
            </h1>
            <p>
              An introduction to essential statistical methods and more advanced
              techniques necessary for robust data analysis in medical research.
            </p>
          </div>
          <Link
            to={"/blogthree"}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="rounded-md bg-orange-600 px-5 py-2 text-white hover:scale-125 hover:bg-orange-400">
              Continue
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LearnCom;
